import { Link } from 'gatsby'
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser'
import React from 'react'
import isExternalLink from '../utils/isExternalLink'
import isHTMLReactParserElement from '../utils/isHTMLReactParserElement'

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (!isHTMLReactParserElement(domNode)) {
      return
    }

    if (domNode.name === 'a' && domNode.attribs.href) {
      if (!isExternalLink(domNode.attribs.href)) {
        if (domNode.attribs.href.includes('mailto')) {
          return (
            <a href={domNode.attribs.href}>
              {domToReact(domNode.children, options)}
            </a>
          )
        }
        return (
          <Link to={domNode.attribs.href}>
            {domToReact(domNode.children, options)}
          </Link>
        )
      } else if (isExternalLink(domNode.attribs.href)) {
        return (
          <a href={domNode.attribs.href} target="_blank">
            {domToReact(domNode.children, options)}
          </a>
        )
      }
    }

    if (
      domNode.name === 'img' &&
      domNode.attribs.src &&
      domNode.attribs.src.startsWith('/uploads')
    ) {
      return (
        <img
          {...domNode.attribs}
          src={`${process.env.GATSBY_STRAPI_URL}${domNode.attribs.src}`}
        />
      )
    }

    return
  },
}

const htmlToReact = (content: string) => parse(content, options)

export default htmlToReact
