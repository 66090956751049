import { DOMNode } from 'html-react-parser'
import { Element } from 'domhandler/lib/node'

const isHTMLReactParserElement = (domNode: DOMNode): domNode is Element => {
  const isTag = domNode.type === 'tag'
  const hasAttributes = (domNode as Element).attribs !== undefined
  return isTag && hasAttributes
}

export default isHTMLReactParserElement
